@import url('https://fonts.googleapis.com/css?family=Special+Elite');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
}

.header {
  width: 100%;
  height: 100%;
  background: url('./images/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.header__buttons {
  margin-top: 30px;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

.navbar {
  width: 100vw;
  height: 100px;
  position: fixed;
  bottom: 2;
  top: 0;
  background: #181b2e;
  z-index: 2;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.navbar a:hover {
  background-color: #ff4545;
  background-image: linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
  color: white;
  text-shadow: none;
}

.navbar__container {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: space-between;
}
.navbar__left-logo img {
  width: 100px;
}

.navbar__right {
  display: flex;
  list-style-type: none;
}
.navbar__right li a {
  text-decoration: none;
  border-radius: 15px/25px;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  padding: 0 13px;
}

/* Helpers */
.container {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}
.bgMain {
  background: #1f2235;
}

/* .h-650 {
  height: 650px;
} */
.alignCenter {
  align-items: center;
}
.justifyConter {
  justify-content: center;
}

/* Grid System */
.row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.col-6 {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.col-7 {
  width: 50%;
}

.col-4 {
  width: 33.33333333333333%;
}

/* header */
.header__content {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  color: #fff;
  padding-top: 6.5rem;
  padding-bottom: 2rem;
}
.header__ul {
  list-style-type: none;
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
}
.header__ul li {
  width: 50px;
  height: 50px;
  border: 2px solid #ff4a57;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  transition: all 0.5s linear;
  cursor: pointer;
}
.header__ul li:hover {
  background: #fff;
  border: 2px solid transparent;
}
.header__ul li:hover .headerIcon {
  color: #ff4a57;
}
.header__ul li:first-child {
  margin-left: 0;
}
.header__section h1 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 20px;
}
.header__section p {
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 15px;
}

.tm-textbox {
  padding: 12px;
  font-size: 1.1rem;
  color: #ffffff;
  /* line-height: 1; head has to be changed */
  background: rgba(88, 95, 140, 0.4);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 12px;
  /* border: 1px solid rgba(255, 255, 255, 0.2); */
}

.btn-cv {
  outline: none;
  border: none;
  color: #fff;
  text-decoration: none;
}

.btn-outline {
  border: 2px solid #ff4a57;
  border-radius: 50px;
  padding: 13px 40px;
}
.btn-smart {
  background: #ff4a57;
  padding: 13px 40px;
  border-radius: 50px;
}
.play {
  font-size: 25px !important;
  padding-top: 10px;
}
.banner__img {
  padding: 0 30px;
  /* margin-top: 150px; */
  margin-bottom: -1rem;
}

/* Servives */
.services {
  background: #1f2235;
  padding: 10px 0;
  color: #fff;
}
.quotes {
  font-family: 'Special Elite', cursive;
  color: #515163;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

blockquote {
  font-weight: 100;
  font-size: 2rem;
  max-width: 600px;
  line-height: 1.4;
  position: relative;
  margin: 0;
  padding: 0.5rem;
}

blockquote:before,
blockquote:after {
  position: absolute;
  color: #f1efe6;
  font-size: 8rem;
  width: 4rem;
  height: 4rem;
}

blockquote:before {
  content: '“';
  left: -5rem;
  top: -2rem;
}

blockquote:after {
  content: '”';
  right: -6rem;
  bottom: 1rem;
}

cite {
  line-height: 3;
  text-align: left;
}

.common {
  width: 33.33333333333333%;
  margin: 20px auto;
  text-align: center;
}

.heading {
  margin-bottom: 20px;
  font-weight: 500;
  color: #ff4a57;
  font-size: 16px;
}
.mainHeader {
  font-size: 32px;
  margin-bottom: 20px;
  color: #fff;
}
.mainContent {
  font-size: 16px;
  color: #dfdfdf;
}
.commonBorder {
  background: #ff4a57;
  height: 3px;
  width: 100px;
  margin: 30px auto;
}

.services__box {
  padding: 40px;
  background: #23263a;
  color: #fff;
  margin: 20px;
  transition: all 0.5s linear;
}
.services__box:hover {
  background: #ff4a57;
}
.commonIcons {
  font-size: 40px;
  color: #ff4a57;
  margin-bottom: 15px;
}
.services__box:hover .commonIcons {
  color: #fff;
}
.services__box-header {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
}
.services__box-p {
  font-size: 14px;
  color: #dfdfdf;
}
.about {
  width: 100%;
  background: #23263a;
  padding: 10px 0;
}
.about__img {
  padding: 10px;
}
.about__img img {
  width: 250px;
  height: 300px;
}
.about__info {
  color: #fff;
}
.about__info h1 {
  margin-bottom: 30px;
}
.about__info-p1 {
  font-size: 14px;
  margin-bottom: 20px;
}
.about__info-p2 {
  font-size: 14px;
}

.info__contacts {
  margin-top: 20px;
}
.info__contacts strong {
  color: #ff4a57;
}
.info__contacts p {
  margin-top: 3px;
}
.info__contacts .col-6 {
  margin: 10px 0;
}
/* Prices */
.prices {
  width: 100%;
  padding: 100px 0;
  background: #1f2235;
}
.price {
  background: #23263a;
  padding: 25px;
  border-radius: 8px;
  margin: 35px 20px;
  color: #fff;
  text-align: center;
}
.priceHeading {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 15px;
}
.price__rs {
  font-size: 70px;
  font-weight: 200;
  margin-bottom: 15px;
}
.price__rs span {
  font-size: 25px;
}
.price ul {
  list-style-type: none;
}
.price ul li {
  margin: 5px 0;
  border-bottom: 1px solid #3c3c3c;
  font-size: 14px;
  padding: 15px 0;
}
.price__btn {
  margin-top: 40px;
}
.contact {
  padding: 100px 0;
  background: #23263a;
}
.contactSection-logo {
  text-align: center;
  margin-bottom: 20px;
}
.contactSection-logo img {
  margin: 0 auto;
  width: 130px;
}
.contactSection p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
}

.contactCircles {
  width: 100%;
  display: flex;
  list-style: none;
  justify-content: center;
}
.contactCircles li {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #1f2235;
  margin: 0 10px;
  transition: all 0.5s linear;
  cursor: pointer;
}
.contactCircles li:hover {
  background: #ff4a57;
}
.toggle {
  position: fixed;
  top: 20px;
  right: 25px;
  background: #000;
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: none;
}
@media (max-width: 765px) {
  body {
    width: 100vw;
  }
  .about__info {
    text-align: left;
  }

  .container {
    width: 100%;
    padding: 0 47px;
  }

  .marquee-mobile {
    height: 250px;
  }
  .header {
    width: 100%;
    height: 100%;
    background: rgb(34, 31, 48);
  }
  .col-6 {
    width: 100%;
  }
  .col-3 {
    width: 100%;
  }
  .col-4 {
    width: 100%;
  }
  .navbar__right {
    flex-direction: column;
    width: 100%;
    align-items: center;
    background: #1f2235;
    position: fixed;
    top: 0;
    left: 0;
  }
  .navbar__right li a {
    text-decoration: none;
    border-radius: 10px/20px;
    color: #fff;
    font-size: 1.4em;
    padding: 0 13px;
  }
  .navbar a:hover {
    background-color: #ff4545;
    background-image: linear-gradient(
      top,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.2)
    );
    color: white;
    text-shadow: none;
  }

  .toggle {
    display: flex;
  }
  .banner__img img {
    display: none;
  }
  .header__section h1 {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .header__section p {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 50px;
  }
  .common {
    width: 100%;
  }

  blockquote:before,
  blockquote:after {
    position: absolute;
    color: #f1efe6;
    font-size: 6rem;
    width: 3rem;
    height: 3rem;
  }

  blockquote:before {
    content: '“';
    left: -3rem;
    top: -1rem;
  }

  blockquote:after {
    content: '”';
    right: -3rem;
    bottom: 1rem;
  }

  .h-650 {
    height: auto;
  }
}

/* Animation  */
@import 'https://fonts.googleapis.com/css2?family=Inter:wght@100;700&display=swap';
@keyframes scroll1 {
  0% {
    transform: translate(100%);
  }
  100% {
    transform: translate(-100%);
  }
}
@keyframes scroll2 {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(-200%);
  }
}

section {
  margin-top: -2rem;
  padding: 0;
  position: relative;
  width: 100%;
  height: 50vh;
  overflow: hidden;
}
section .scroll {
  width: 100%;
  display: flex;
}
section .scroll div {
  color: #000000;
  font-size: 4em;
  background: #eeeeeeee;
  white-space: nowrap;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: -2px;
  animation: scroll1 30s linear infinite;
  animation-delay: -40s;
}
section .scroll div:nth-child(2) {
  animation: scroll2 30s linear infinite;
  animation-delay: -25s;
}
section .scroll div span {
  font-weight: 80;
}
section .scroll:nth-child(even) div {
  animation-direction: reverse;
}
section .scroll:nth-child(even) div:nth-child(2) {
  animation-direction: reverse;
}
section .scroll.text-1 {
  transform: rotate(8deg) translateY(100px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

/* Marquee section */

.Marquee {
  background: -webkit-linear-gradient(225deg, #89d6ffd8, #1f2235);
  background: -moz-linear-gradient(225deg, #89d6ffd8, #1f2235);
  background: -o-linear-gradient(225deg, #89d6ffd8, #1f2235);
  background: -ms-linear-gradient(225deg, #89d6ffd8, #1f2235);
  background: linear-gradient(-135deg, #89d6ffd8, #1f2235);
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1em;
  color: #fff;
  font-weight: 200;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  overflow: hidden;
}
.Marquee-content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-animation: marquee 10s linear infinite running;
  -moz-animation: marquee 10s linear infinite running;
  -o-animation: marquee 10s linear infinite running;
  -ms-animation: marquee 10s linear infinite running;
  animation: marquee 10s linear infinite running;
}
.Marquee-content:hover {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  -ms-animation-play-state: paused;
  animation-play-state: paused;
}
.Marquee-tag {
  width: 200px;
  margin: 0 0.5em;
  padding: 0.5em;
  background: rgba(0, 0, 0, 0.589);
  border-radius: 0.8rem;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.Marquee-tag:hover {
  background: rgba(252, 89, 89, 0.932);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  cursor: pointer;
}
@-moz-keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -o-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}
@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -o-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}
@-o-keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -o-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}
@keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -o-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}

.table-custom {
  margin: auto;
  width: 50%;
  padding: 10px;
  max-width: 100%;
}
.text {
  text-align: center;
}

/* WP Button */
.popout {
  position: fixed;
  bottom: 2.5rem;
  right: 2.5rem;
}

.popout .btn {
  display: inline-block;
  position: relative;
  outline: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  line-height: 60px;
  text-align: center;
  font-size: 32px;
  z-index: 999;
  background: #eee;
  color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  transition: 0.2s opacity ease-in-out;
  -webkit-transition: 0.2s opacity ease-in-out;
}
.popout .btn .wp-icon {
  display: inline-flex;
}
.popout .btn.active {
  visibility: hidden;
  opacity: 0;
}

.popout .btn:after {
  display: block;
  position: absolute;
  top: 0;
  content: '';
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: #10f568;
  z-index: -2;
  -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
  -moz-transition: -moz-transform 0.2s, opacity 0.2s;
  transition: transform 0.2s, opacity 0.2s;
}

.popout .btn:active:after {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  opacity: 0;
}
